import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { ClientFormUser } from '../../models/ClientFormUser';
import { ImageSize, ProfileImageStack } from './ProfileImageStack';
import { ClientFormUserRole, ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import { Input, InputStyle } from '../shared/form-control/Input';
import { useTranslation } from 'react-i18next';
import XIcon from '../shared/icon/XIcon';
import {
  autoUpdate,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
  size as fuiSize,
  flip,
} from '@floating-ui/react';
import PlusIcon from '../shared/icon/PlusIcon';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import User from '../../models/User';
import ObjectUtils from '../../utils/ObjectUtils';

type SingleUserSelectProps = {
  user?: ClientFormUser | null;
  onAddUser: (user: ClientFormUser) => void;
  onRemoveUser?: (user: ClientFormUser) => void;
  readOnly?: boolean;
  size?: 'normal' | 'small';
  assignedUserRole?: ClientFormUserRoleValues;
  listUsersFilterFn?: (user: User) => boolean;
};

const DefaultListUsersFilterFn = () => true;

const SingleUserSelect: FC<SingleUserSelectProps> = (props) => {
  const {
    user,
    onAddUser,
    onRemoveUser,
    readOnly,
    size = 'normal',
    assignedUserRole = ClientFormUserRole.Owner,
    listUsersFilterFn = DefaultListUsersFilterFn,
  } = props;
  const { data: clientUsers = [] } = useFetchClientUsers();
  const [selectedUser, setSelectedUser] = useState<ClientFormUser | null | undefined>(user);
  const [suggestionValue, setSuggestionValue] = useState(user ? (!user.firstName ? user.email : `${user.firstName} ${user.lastName || ''}`) : '');
  const { t } = useTranslation('common');

  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift(), fuiSize()],
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
    strategy: 'fixed',
  });

  const click = useClick(context);
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'dialog',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, focus, dismiss, role]);

  useEffect(() => {
    // if only user Id is provided, try find the user from the list
    if (!user?.firstName && !user?.lastName && !!user?.id) {
      let result = clientUsers.find((u) => u.id === user.id) as ClientFormUser;
      if (result) {
        result = ObjectUtils.DeepClone(result);
        result.role = assignedUserRole;
        setSelectedUser(result);
      }
    }
  }, [assignedUserRole, clientUsers, user?.firstName, user?.id, user?.lastName]);

  const selectUser = useCallback(
    (userId: string) => {
      let selectedUser = clientUsers.find((user) => user.id === userId) as ClientFormUser;
      if (selectedUser) {
        selectedUser = ObjectUtils.DeepClone(selectedUser);
        selectedUser.role = assignedUserRole;
        if (size !== 'small') {
          setSelectedUser(selectedUser);
        }
        onAddUser(selectedUser);
        setIsOpen(false);
        setSuggestionValue('');
      }
    },
    [assignedUserRole, clientUsers, onAddUser, size],
  );

  const removeUser = () => {
    setSelectedUser(null);
    setSuggestionValue('');
    if (selectedUser) {
      onRemoveUser?.(selectedUser);
    }
  };

  const filteredSuggestions = useMemo(
    () =>
      clientUsers
        .filter(listUsersFilterFn)
        .filter((user) => {
          if (!suggestionValue) {
            return true;
          }

          if (selectedUser?.id === user.id) {
            return false;
          }

          const value = suggestionValue.toLowerCase();
          const name = user.firstName?.toLowerCase() || '';
          const surname = user.lastName?.toLowerCase() || '';
          const fullName = `${name} ${surname}`;

          return name.indexOf(value) > -1 || surname.indexOf(value) > -1 || fullName.indexOf(value) > -1;
        })
        .map((user) => {
          const fullname = (!user.firstName ? user.email : `${user.firstName} ${user.lastName || ''}`) || '';
          return { id: user.id as string, value: fullname as string, text: fullname };
        }),
    [clientUsers, listUsersFilterFn, selectedUser?.id, suggestionValue],
  );

  return (
    <div className="relative flex" onClick={(e) => e.stopPropagation()}>
      <div className={`flex ${readOnly ? '' : 'cursor-pointer'} items-center gap-2`} ref={refs.setReference} {...getReferenceProps()}>
        {selectedUser && size !== 'small' && <ProfileImageStack users={[selectedUser]} size={ImageSize.S} />}
        {!user && (
          <div className="flex items-center gap-2">
            <PlusIcon
              data-cy="toggle-contributer-add"
              className={`text-gray-1 ${size === 'normal' ? 'h-9 w-9' : 'h-6 w-6 p-[5px]'} hover:bg-accent-1 bg-gray-6 rounded-full transition-colors hover:text-white`}
            />
            {size !== 'small' && <span>{t('unassigned')}</span>}
          </div>
        )}
        {!!user && (
          <>
            <div className="flex items-center justify-between gap-2">
              <span>{selectedUser?.fullName}</span>
            </div>
            {!readOnly && (
              <XIcon
                className="h-5 w-5"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  removeUser();
                }}
              />
            )}
          </>
        )}
      </div>

      {isOpen && !readOnly && (
        <div
          ref={refs.setFloating}
          style={
            {
              ...floatingStyles,
              wordBreak: 'break-word',
              hyphens: 'auto',
              zIndex: 9999,
              width: 'fit',
              minWidth: '20rem',
            } as const
          }
          {...getFloatingProps()}
          className="max-h-80 max-w-sm overflow-y-auto rounded-md border border-gray-200 bg-white py-2 pt-0 shadow-md"
        >
          <div className="sticky top-0 z-30 bg-white px-2 pb-4 pt-2">
            <Input
              style={InputStyle.MINIMAL}
              value={suggestionValue}
              onChange={(e) => setSuggestionValue(e.target.value)}
              placeholder={t('list.filter.search')}
            />
          </div>
          <div className="overflow-y-auto pl-2">
            {filteredSuggestions.map((u) => {
              const user = clientUsers.find((x) => x.id === u.id) as ClientFormUser;
              return (
                <div key={u.id} className="flex cursor-pointer items-center py-2 hover:bg-gray-100 hover:text-black" onClick={() => selectUser(u.id)}>
                  <ProfileImageStack users={[user]} size={ImageSize.S} />
                  <div className="ml-3 font-medium text-black">{u.text}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleUserSelect;
